body {
    font-family: 'Courier New', Courier, monospace;
    background-color: gray;
    font-weight: 700;

}

.card-service {
    height: 425px;

}

.container .p-5 {
    padding: 10rem !important;
}

.main-img {
    height: auto;
    /* transform: rotate(-90deg); */
    width: auto;
    max-width: 100%;
    opacity: 0.2;

}

/* .footer-contain {
    margin-left: 29px;
   
} */

.container {
    max-width: 90%;
    padding: 6rem !important;
}

.card-service .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    overflow: hidden
}

.card-service .header.sevices-img {
    height: auto;
    width: auto;
    max-height: 100%;
    max-width: 100%;
}

.backound-page {
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: -1;
    margin-top: 10vh;
}

.backound-page .bg-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 2;
}

/* display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    overflow: hidden */

.sidebar {
    background-color: #6c757d;
    position: fixed;
    z-index: 999;
    border-radius: 10px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-top: 10px;
    max-width: 240px;

}

.toast-block{
    display: flex;
    background-color: #6c757d;
    position: absolute;
    z-index: 999;
    border-radius: 10px;
    margin-bottom: 50px;
    margin-right: 20px;
    margin-top: 10px;
    max-width: 280px;
   right:0px;
}

.banner .banner-main {
    position: absolute;
    top: 0%;
    transform: translateY(29%);
    padding-left: 15%;
    padding-right: 15%;
}

.banner .banner-main p {
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.banner .banner-main .banner-header {
    font-size: 65px;
    font-weight: bold;
}

.banner .banner-main .banner-detail {
    font-size: calc(1.3rem + .6vw);
}

.about-main .about-header {
    font-size: 65px;
    font-weight: bold;
}

.about-main .about-detail {
    font-size: calc(1.275rem + .3vw);
}

.about-main .about-mini {
    font-size: 35px;
    font-weight: bold;
}

.about-main .contact-label{
    font-size: 1.5rem;
}

.page-footer .container .row{
    padding-left: 20px;
}