@media only screen and (max-width: 1366px) {
    .navbar-expand-lg .navbar-toggler {
        display: inherit;
    }

    .navbar-expand-lg .navbar-collapse {
        display: none !important;
    }
}

@media only screen and (max-width:1400px) {
    .banner .banner-main {
        /* transform: translateY(18%); */
    }
}

@media only screen and (max-width:1152px) {
    .banner .banner-main {
        /* transform: translateY(10%); */
    }

    .banner .banner-main p {
        margin-top: 1.5rem;
    }

    .banner .banner-main .banner-header {
        font-size: 57px;
    }
}

@media only screen and (max-width:992px) {
    .banner .main-img {
        height: 100%;
        width: 100%;
    }

    .banner .banner-main .banner-header {
        font-size: 45px;
    }

    .banner .banner-main .banner-detail {
        font-size: calc(1.3rem + .3vw);
    }

    .banner .banner-main .banner-detail {
        margin-bottom: -1rem;
    }
   .banner .banner-main p{
    margin-top: 0.5rem
   }
}

@media only screen and (max-width:768px) {
    .banner .banner-main {
        /* transform: translateY(5%); */
    }
    .banner .banner-main .banner-header {
        font-size: 37px;
    }
    .banner .banner-main .banner-detail {
        font-size: calc(1.3rem + .1vw);
    }
    .banner .banner-main p{
        margin-top: 0.1rem
       }
}

@media only screen and (max-width:690px) {
    .banner .banner-main {
        /* transform: translateY(1.5%); */
    }
    .banner .banner-main .banner-header {
        font-size: 32px;
    }
    .banner .banner-main .banner-detail {
        font-size: calc(1.1rem);
    }
    .banner .banner-main p{
        margin-top: 0.1rem
       }
}

@media only screen and (max-width:550px) {
    .banner .banner-main {
        /* transform: translateY(1%); */
    }
    .banner .banner-main .banner-header {
        font-size: 27px;
    }
    .banner .banner-main .banner-detail {
        font-size: calc(1rem);
    }
    .banner .banner-main p{
        margin-top: 0.1rem
       }
}

@media only screen and (max-width:1152px) {
    .about-main .about-header{
       font-size: 57px;
   }
   .container{
    padding: 4rem !important;
   }
   .about-main .about-detail {
    font-size: calc(1.275rem + .3vw);
}
.about-main .about-mini {
    font-size: 35px;
    font-weight: bold;
}
.about-main .contact-label{
    font-size: 1.5rem;
}
}

@media only screen and (max-width:992px) {
    .about-main .about-header{
        font-size: 45px;
    }
    .container{
        padding: 3rem !important;
       }
       .about-main .about-detail {
        font-size: calc(1.2rem );
    }
    .about-main .about-mini {
        font-size: 32px;
        font-weight: bold;
    }
    .about-main .contact-label{
        font-size: 1.2rem;
    }
 }

 @media only screen and (max-width:768px) {
    .about-main  .about-header{
        font-size: 37px;
    }
    .container{
        padding: 2rem !important;
       }
       .about-main .about-mini {
        font-size: 28px;
        font-weight: bold;
    }
    .about-main .contact-label{
        /* font-size: 1.2rem; */
        font-size:1.2rem
    }
 }

 @media only screen and (max-width:690px) {
    .about-main  .about-header{
        font-size: 32px;
    }
    .container{
        padding: 1.5rem !important;
       }
       .about-main .about-detail {
        font-size: calc(1.1rem );
    }
    .about-main .about-mini {
        font-size: 25px;
        font-weight: bold;
    }
    .about-main .contact-label{
        font-size: 1rem;
    }
 }

 @media only screen and (max-width:550px) {
    .about-main  .about-header{
        font-size: 27px;
    }
    .container{
        padding: 1rem !important;
       }
       .about-main .about-detail {
        font-size: calc(0.9rem );
    }
    .about-main .about-mini {
        font-size: 20px;
        font-weight: bold;
    }
    .about-main .contact-label{
        font-size: 1rem;
    }
 }

 @media only screen and (min-width:1200px)  {
     .contact-page{
        padding: 4rem !important;
     }
 }


@media screen and (max-width:1500px) {
 /* .background-page .bg-image{
     width: 30vw;
     display:flex;
     justify-content:center;
     position: absolute;
 } */
    /* .backound-page .bg-image{
        width: 30vw;
        display: flex;
        justify-content: center;
        position: fixed;

        position: absolute;
    top: 0%;
    transform: translateY(29%);
    padding-left: 15%;
    padding-right: 15%;
    } */
}